<template>
    <div class="flex flex-col w-full h-auto mb-12">
        <!-- Header -->
        <div class="flex w-full h-auto justify-center items-center">
            <span class="Helvetica text-2xl font-bold text-green-500  justify-center normal-case">{{$t('expedition_information')}}</span>
        </div>
        <!-- ******************************** -->
        <div class="px-6 mt-5">
            <div class="flex bg-white w-full space-x-2">
                <div  class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                    <div class="flex w-full mt-1">
                        <span class="rounded-full h-12 w-12 bg-gray-200">
                            <i class="material-icons p-3 text-green-400 ">person</i>
                        </span>
                        <div class="ml-5 w-full">
                            <div class="text-base mt-1 font-semibold text-gray-600 pb-10">{{$t('package_information')}}</div>
                            <div class="text-sm font-light text-gray-500"></div>
                            <div class="w-full space-y-4">
                                <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                        <label for="transportmode" class="bg-white text-gray-700 px-1">{{$t('transport_mode')}}
                                            <span :class="$colors.required">*</span></label>
                                        </p>
                                    </div>
                                    <p>
                                        <select id="type" name="transportMode" disabled autocomplete="type" v-model="item.transportMode" class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                                            <option class="bg-white" selected :value="null">{{$t('transport_mode')}}</option>
                                            <option v-for="(trans, index) in transports" :key="index" :value="trans">{{ trans }}</option>
                                        </select>
                                    </p>
                                </div>
                                <div v-if="item.type && item.type == 'transfer'" class="flex">
                                    
                                    <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                            <p>
                                                <label for="numberOfPackages" class="bg-white text-gray-700 px-1">{{$t('number_of_packages')}} <span :class="$colors.required">*</span></label>
                                            </p>
                                        </div>
                                        <p>
                                            <input  min="1" type="number" v-model="item.numberOfPackages" class="py-2 text-xs px-2 outline-none block h-full w-full" disabled />
                                        </p>
                                    </div>
                                </div>
                               
                                <div class="flex">
                                <div
                                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                            <label for="Contry" class="bg-white text-gray-700 px-1">{{$t('from')}} <span :class="$colors.required">*</span></label>
                                        </p>
                                    </div>
                                    <p>
                                        <input  autocomplete="false" tabindex="0" type="text" v-model="item.country" class="py-2 text-xs px-2 outline-none block h-full w-full" disabled />
                                    </p>
                                </div>
                                 <div
                                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1 ml-2">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                            <label for="country_to" class="bg-white text-gray-700 px-1">{{$t('to')}} <span :class="$colors.required">*</span></label>
                                        </p>
                                    </div>
                                    <p>
                                        <input  autocomplete="false" tabindex="0" type="text" v-model="item.country_to" class="py-2 text-xs px-2 outline-none block h-full w-full" disabled />
                                    </p>
                                </div>
                              </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- ****************************** Carrier Information and expedition info *********************************************-->
                <div class="flow-root w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                   
                     <div class="flex w-full mt-5">
                        <span class="rounded-full h-12 w-12 bg-gray-200">
                            <i class="material-icons p-3 text-green-400 ">local_grocery_store</i>
                        </span>
                        <div class="ml-5 w-full">
                            <div class="text-base mt-1 font-semibold text-gray-600 pb-10">{{$t('update_expedition_information')}}</div>
                            <div class="w-full space-y-4">
                                <div class="flex">
                                    <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                            <p>
                                                <label for="status" class="bg-white text-gray-700 px-1">{{$t('status')}}
                                                    <span :class="$colors.required">*</span>
                                                </label>
                                            </p>
                                        </div>
                                        <p>
                                            <select :disabled="item.status === 'received'?true:false" name="status" @change="onChangeStatus($event)"  :style="colorStatus(item.status)" v-model="item.status" class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                                                <option class="bg-white" selected :value="null">{{$t('status')}}</option>
                                                <option class="bg-white text-gray-600" v-for="(value, index) in statusExpedition" :key="index" :value="value">
                                                    {{ value }}
                                                </option>
                                            </select>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                            <label for="arrivalDate" class="bg-white text-gray-700 px-1">{{$t('arrival_date')}}
                                                <span :class="$colors.required">*</span></label>
                                        </p>
                                    </div>
                                    <p>
                                        <input id="arrivalDate"  type="datetime-local"  v-model="item.arrivalDate" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                                    </p>
                                </div>
                                      <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                            <label for="date"  class="bg-white text-gray-700 px-1">{{$t('expedition_date')}} <span :class="$colors.required">*</span></label>
                                        </p>
                                    </div>
                                    <p>
                                         <input id="date" type="datetime-local" v-model="formattedDate" disabled  class="py-2 text-xs px-2 outline-none block h-full w-full" />
                                    </p>
                                </div>
                               
                               
                               
                                <!-- <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                            <label for="customerPhone" class="bg-white text-gray-700 px-1">Paid 
                                                <span :class="$colors.required">*</span></label>
                                        </p>
                                    </div>
                                        <p class="flex items-center justify-center w-full">
                                     
                                            <label  :for="item._id" class="flex items-center cursor-pointer">
                                            <div class="relative">
                                                <input :id="item._id" type="checkbox" class="hidden" v-model="item.paid" />
                                                <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                                                <div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                                            </div>
                                            
                                            <div class="ml-3 text-gray-700">{{ item.paid  }}</div>
                                            </label>
                                        </p>
                                </div> -->
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
            <!--*******************  Product Order ***************************************-->
            <div :id="item._id"  class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-10 rounded-lg">
                <div class="flex w-full mt-1">
                    <span class="rounded-full h-12 w-12 bg-gray-200">
                        <i class="material-icons p-3 text-green-400 ">local_mall</i>
                    </span>
                    <div class="ml-5 w-full">
                        <div class="text-base mt-1 font-semibold text-gray-600 pb-10">{{$t('expedition_products')}} </div>
                        <div class="w-full space-y-4">
                            <div v-if="item.status == 'received'"  class="grid lg:grid-cols-2 gap-6">

                                <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                        <label for="weight" class="bg-white text-gray-700 px-1">{{$t(item.type && item.type=='transfer'?$t('number_of_packages'):$t('weight'))}}  <span :class="$colors.required">*</span></label>
                                    </p>
                                    </div>    
                                    <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                                    <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                                         
                                        <svg  v-if="item.type && item.type=='transfer'" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"/></svg>
                                        <span v-else>KG</span>

                                    </span>
                                    </div>
                                    <input v-if="item.type && item.type=='transfer'" disabled type="number" v-model="item.numberOfPackages" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                    <input v-else disabled type="number" v-model="item.weight" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                </div>

                                <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                        <label for="seller" class="bg-white text-gray-700 px-1"> {{$t('expedition_fees')}}
                                        <span :class="$colors.required">*</span></label>
                                    </p>
                                    </div>    
                                    <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                                        <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                                            <sup class="uppercase" v-if="idWharhouse"> {{idWharhouse.currency}} </sup><span class="select-none ml-2"><img :src="idWharhouse?idWharhouse.flag:''" class="flag-img"></span>
                                        </span>
                                    </div>
                                    <input disabled type="number" v-model="item.fees" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                </div>

                            </div>
                            <div class="w-full">
                                <table class="min-w-full w-full">
                                    <thead class="text-white">
                                        <tr class="bg-green-600  flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                            <th class="p-2 justify-center"> {{$t('image')}}</th>
                                            <th class="p-2 justify-center"> {{$t('name')}}</th>
                                            <th v-if="item.status == 'received'" class="p-2 justify-center"> {{$t(item.type && item.type=='transfer'?`Nombre de colis total`:`Le poids total`)}}</th>
                                            <th style=" width: 200px;"  v-if="item.status == 'received'" class="p-2 justify-center">{{$t(item.type && item.type=='transfer'?`Prix de 1 colis`:`Prix de 1 kilo`)}} </th>
                                            <th class="p-2 justify-center">{{$t('quantity_sent')}}</th>
                                            <th colspan="2" class="p-2 justify-center"> {{$t('quantity_received')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="flex-1 sm:flex-none">
                                        <tr v-for="it in item.details" :key="it._id" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                                                <div> <img :alt="it.product.name" :src="it.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-16 w-16 rounded-md mx-auto" /> </div>
                                            </td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                 <!---    -->
                                                <div class="w-full">
                                                        <div class="flex">
                                                            <svg class="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7 24h-5v-9h5v1.735c.638-.198 1.322-.495 1.765-.689.642-.28 1.259-.417 1.887-.417 1.214 0 2.205.499 4.303 1.205.64.214 1.076.716 1.175 1.306 1.124-.863 2.92-2.257 2.937-2.27.357-.284.773-.434 1.2-.434.952 0 1.751.763 1.751 1.708 0 .49-.219.977-.627 1.356-1.378 1.28-2.445 2.233-3.387 3.074-.56.501-1.066.952-1.548 1.393-.749.687-1.518 1.006-2.421 1.006-.405 0-.832-.065-1.308-.2-2.773-.783-4.484-1.036-5.727-1.105v1.332zm-1-8h-3v7h3v-7zm1 5.664c2.092.118 4.405.696 5.999 1.147.817.231 1.761.354 2.782-.581 1.279-1.172 2.722-2.413 4.929-4.463.824-.765-.178-1.783-1.022-1.113 0 0-2.961 2.299-3.689 2.843-.379.285-.695.519-1.148.519-.107 0-.223-.013-.349-.042-.655-.151-1.883-.425-2.755-.701-.575-.183-.371-.993.268-.858.447.093 1.594.35 2.201.52 1.017.281 1.276-.867.422-1.152-.562-.19-.537-.198-1.889-.665-1.301-.451-2.214-.753-3.585-.156-.639.278-1.432.616-2.164.814v3.888zm3.79-19.913l3.21-1.751 7 3.86v7.677l-7 3.735-7-3.735v-7.719l3.784-2.064.002-.005.004.002zm2.71 6.015l-5.5-2.864v6.035l5.5 2.935v-6.106zm1 .001v6.105l5.5-2.935v-6l-5.5 2.83zm1.77-2.035l-5.47-2.848-2.202 1.202 5.404 2.813 2.268-1.167zm-4.412-3.425l5.501 2.864 2.042-1.051-5.404-2.979-2.139 1.166z"/></svg>
                                                            <div class="text-xs leading-5 capitalize rounded-full text-gray-900 font-bold">{{ it.product.name }}  <span class="inline-flex" v-if="item.type && item.type == 'transfer'">(<img :src="$f.getCountryCode(item.country)" class="w-4 h-4 rounded-full">)</span></div> 
                                                        </div>  
                                                        <span  v-if="item.type && item.type == 'transfer' &&  it.productToTransfer && it.productToTransfer._id" class="ware_prin"> 
                                                                <div :class="!item._id &&  ((it.productToTransfer && !it.productToTransfer._id) || !it.productToTransfer)?'animation-pulse':''"  class="border ml-6 hover:border-blue-400  w-10/12 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                                    <p>
                                                                        <label style="font-size: 9px;" for="zonessous" class="bg-white text-gray-700 px-1">{{$t('Transférer Produit à')}} <span class="inline-flex">(<img :src="$f.getCountryCode(item.country_to)" class="w-4 h-4  rounded-full">)</span> <span :class="$colors.required">*</span></label>
                                                                    </p>
                                                                    </div>
                                                                    <p :title="it.productToTransfer.name">
                                                                        {{ it.productToTransfer.name }}
                                                                    </p>
                                                            </div>
                                                        </span >
                                                    </div>  
                                                <!--   -->
                                            </td>
                                            <td  v-if="item.status == 'received'" class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">   
                                                    <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                                                        <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                                                           <svg  v-if="item.type && item.type=='transfer'" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"/></svg>
                                                           <span v-else>KG</span>
                                                        </span>
                                                    </div>
                                                    <input type="number" :disabled="item.isProcessed" v-model="it.weightOrPackages" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                                </div>
                                            </td>
                                             <td  style=" width: 200px;"  v-if="item.status == 'received'" class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">   
                                                    <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                                                        <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                                                            <sup class="uppercase" v-if="idWharhouse"> {{idWharhouse.currency}} </sup><span class="select-none ml-2"><img :src="idWharhouse?idWharhouse.flag:''" class="flag-img"></span>
                                                        </span>
                                                    </div>
                                                    <input  type="number" :disabled="item.isProcessed" v-model="it.price_one_kiloOrPackage" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                                </div>
                                            </td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                 <input id="sent" :disabled="CheckExpeditionRecived" type="number" min="0" v-model="it.quantity.sent" class="py-1 border-4 border-light-blue-500 border-opacity-100  text-xs px-2 outline-none block h-full w-full" />
                                            </td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                <table class="min-w-full">
                                                   <thead>
                                                       <tr>
                                                         <th class="text-center bg-gray-200 px-4 py-1 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">Ok/Good</th>
                                                         <th class="text-center bg-gray-200 px-4 py-1 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">Defective</th>
                                                       </tr>
                                                    </thead>
                                                    <tbody class="bg-white"> 
                                                        <tr> 
                                                          <td class="px-2 py-1 border whitespace-no-wrap"><div class="flex items-center"> <input id="Received" :disabled="CheckExpeditionRecived || item.status !== 'received'" type="number" min="0" :max="it.quantity.sent" v-model="it.quantity.received" class="py-1 border-4 border-light-blue-500 border-opacity-100  text-xs px-2 outline-none block h-full w-full" /></div></td>
                                                          <td class="px-2 py-1 border whitespace-no-wrap"><div class="flex items-center">
                                                             <input id="Defective" :disabled="CheckExpeditionRecived || item.status !== 'received'" type="number" min="0" :max="it.quantity.sent" v-model="it.quantity.defective" class="py-1 border-4 border-light-blue-500 border-opacity-100  text-xs px-2 outline-none block h-full w-full" />
                                                          </div>
                                                          </td>    
                                                       </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>        
</template>
<script>
export default {
    props: {
        config: { required: true, type: Object},
        item:{ default: [] },
        index:{ type: Number },
        statusExpedition:{ type: Array },
        transports:{ type: Array },
        CheckExpeditionRecived:{required:false},  
        idWharhouse: { type: Object},
        
    },
     data() {
      return {
           options:this.$countries,
      }
     },
     watch: {
        'item.details': {
            handler: function(newValue) {
                console.log("Person with price_one_kiloOrPackage:" + newValue.price_one_kiloOrPackage + " modified")
                var vm = this;
                vm.setValue();
               // console.log("New age: " + newValue.age)
            },
            deep: true
        }
    },
    async mounted() {
      await this.CheckQteSentExist();
    },
    methods: {
     setValue: function() {
        console.log('this.item.details----',this.item.details)
       if(this.item.type && this.item.type=='transfer') this.item.numberOfPackages= this.item.details.map(it => parseFloat(it.weightOrPackages)).reduce((prev, next) => prev + next);
       else this.item.weight= this.item.details.map(it => parseFloat(it.weightOrPackages)).reduce((prev, next) => prev + next);
        this.item.fees= this.item.details.map(it => (parseFloat(it.weightOrPackages)*parseFloat(it.price_one_kiloOrPackage))).reduce((prev, next) => prev + next);
     },
     scrollTo(hash) {
         location.hash = "#" + hash;
      },
      onChangeStatus(event){
        if(event.target.value === 'received'){
             this.scrollTo(this.item._id);
        }
      },   
     colorStatus(status) {
        switch (status) {
            case 'expedited':
                return "color: rgb(233 147 19); border-color: rgb(233 147 19);" 
                break;
            case 'received':
                return  "color:rgb(58 217 18); border-color: rgb(58 217 18);" 
                break;
            case 'cancelled':
                return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);" 
                break;
        }
        },
      CheckQteSentExist: function () {
         this.$emit('CheckQteSentExist',this.item);
      }, 
    },
    computed: {
      formattedDate() {
        return this.$moment(this.item.date).format('yyyy-MM-DDTHH:mm');
      },
       formattedDateArrival() {
        return this.$moment(item.arrivalDate).format('yyyy-MM-DDTHH:mm');
      },
      
    },
    async mounted() {
        
    },

}
</script>
<style scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}
.ware_prin::after {
    left: -71px;
    height: 36px;
}
.ware_prin::before {
    top: 36px;
    left: -71px;
    width: 16px;
}
</style>